import React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { BasicContentSlider as ContentSlider, SliderCard } from 'smart-react';
import AppFlowDetailArgument from '../../Pages/AppFlowDetailArgument';

/**
 * limit to view recently selected items.
 */

/**
 * Lookup mode of test
 * Slider Card of Test lookup
 */
const AppFlowDetailArgumentSlider = ({
  handleSlide,
  setHandleSlide,
  flowStep,
}) => {
  const children = (
    <SliderCard
      title={'Application Flow Detail Argument'}
      handleSlide={() => setHandleSlide(false)}
      className='eq-lookup detail-arg-slider large-slider'
    >
      <SliderCard.Body>
        <AppFlowDetailArgument flow={flowStep} />
      </SliderCard.Body>
      <SliderCard.Footer>
        <Button
          className={'large-slider-action'}
          icon='cancel'
          onClick={() => setHandleSlide(false)}
          type='button'
        >
          Cancel
        </Button>
      </SliderCard.Footer>
    </SliderCard>
  );

  return (
    <div className='lookup-mode af-lookup'>
      <ContentSlider show={handleSlide} children={children} />
    </div>
  );
};

export default AppFlowDetailArgumentSlider;
