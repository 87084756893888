import React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { Switch } from '@progress/kendo-react-inputs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

export const DataColumns = (
  editID,
  argumentData,
  saveRow,
  cancelEdit,
  setEditRow,
  showDeleteDialog,
) => [
  {
    field: 'UcOssiAfStepArgId',
    title: 'AF Step Argument Id*',
    width: '200px',
    minResizableWidth: 200,
    editable: editID === null || argumentData.some((item) => item.isNew),
  },
  {
    field: 'UcOssiDescr',
    title: 'Description',
    width: '250',
    minResizableWidth: 250,
    editable: true,
  },
  {
    field: 'UcOssiDataVal',
    title: 'Data Value*',
    width: '200px',
    minResizableWidth: 200,
    editable: true,
  },
  {
    field: 'UcOssiUseStackVarFlg',
    title: 'Stack Variable*',
    width: '200px',
    minResizableWidth: 200,
    cell: (props) => (
      <td>
        {props.dataItem.inEdit ? (
          <Switch
            checked={props.dataItem.UcOssiUseStackVarFlg === 1}
            onChange={(e) =>
              props.onChange({
                dataItem: props.dataItem,
                field: props.field,
                value: e.value ? 1 : 0,
              })
            }
          />
        ) : props.dataItem.UcOssiUseStackVarFlg === 1 ? (
          <FontAwesomeIcon icon={faCheck} className='grid-flag-icon' />
        ) : (
          <FontAwesomeIcon icon={faTimes} className='grid-flag-icon' />
        )}
      </td>
    ),
  },
  {
    width: '300',
    minResizableWidth: 300,
    title: 'Actions',
    cell: (props) => (
      <td>
        {props.dataItem.inEdit ? (
          <>
            <Button
              themeColor='primary'
              icon={'save'}
              size='small'
              fillMode={'flat'}
              onClick={() => saveRow(props.dataItem)}
              className='k-button k-mr-2'
            ></Button>
            <Button
              themeColor='light'
              size='small'
              icon={'cancel'}
              fillMode={'solid'}
              onClick={() => cancelEdit(props.dataItem.row_no)}
              className='k-button'
            ></Button>
          </>
        ) : (
          <>
            <Button
              icon={'pencil'}
              themeColor='primary'
              size='small'
              fillMode={'flat'}
              onClick={() => setEditRow(props.dataItem.row_no)}
              className='k-button k-mr-2 k-font-size-sm'
            ></Button>
            <Button
              themeColor='error'
              size='small'
              icon={'trash'}
              fillMode={'flat'}
              onClick={() => showDeleteDialog(props.dataItem)}
            ></Button>
          </>
        )}
      </td>
    ),
  },
];
