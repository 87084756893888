import React, { useState, useEffect } from 'react';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { Loader } from 'smart-react';
import { GenerateNotification, buildNotification } from 'smart-react';
import {
  CREATE_MESSAGE,
  UPDATE_MESSAGE,
  ERROR_MESSAGE,
  DELETE_MESSAGE,
} from '../../../../constants/notificationMessages';
import {
  EVENTS_DATA_TYPES,
  NOTIFICATION_TYPES,
} from '../../../../constants/eventDataTypes';
import {
  listAppFlowDetailArgument,
  createAppFlowDetailArgument,
  updateAppFlowDetailArgument,
  deleteAppFlowDetailArgument,
} from '../Services/AppFlowDetailArgumentService';
import { isFromValid } from 'smart-react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import './AppFlowDetailArgument.scss';
import { APPFLOWDTLARG } from '../../../../constants/applicationConstants';
import { useAuth } from '../../../Core/Context/AuthContext';
import { DataColumns } from '../Components/DataColumns/DataColumns';
const requiredFields = ['UcOssiAfStepArgId', 'UcOssiDataVal'];

/**
 * Component to manage App Flow Detail Arguments
 * @param {Object} props - Component properties
 * @param {Object} props.flow - Flow data containing details such as TenantId, UcOssiAfStepId, etc.
 * @returns {JSX.Element} React component for App Flow Detail Argument
 */
const AppFlowDetailArgument = ({ flow }) => {
  const [argumentData, setArgumentData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editID, setEditID] = useState(null);
  const [visible, setVisible] = useState(false);
  const [checkedDataItem, setCheckedDataItem] = useState([]); // To store selected items for deletion
  const { tenantID, getTokensFromStorage } = useAuth(); // Get tenantID using Auth Context

  /**
   * Fetch data on component mount
   */
  useEffect(() => {
    fetchData();
  }, []);

  /**
   * Fetches argument data from the API
   * Updates the state with the fetched data
   */
  const fetchData = async () => {
    const { accessToken } = getTokensFromStorage();
    setLoading(true);
    const response = await listAppFlowDetailArgument({
      accessToken,
      filter: [],
      afId: flow?.UcOssiAfId,
      dtlNum: flow?.UcOssiAfDtlSeq,
      filter: [
        {
          ColumnName: 'TenantId',
          Op: 'eq',
          ColumnValue: flow?.TenantId,
        },
      ],
    });
    setArgumentData(response?.arguments || []);
    setLoading(false);
  };

  /**
   * Saves a row's data (either updates or creates a new record)
   * @param {Object} dataItem - The data item being saved
   */
  const saveRow = async (dataItem) => {
    setLoading(true);

    const formData = {
      TenantId: tenantID,
      TenantName: flow?.TenantName || '',
      UcOssiAfId: flow?.UcOssiAfStepId,
      UcOssiProdId: flow?.UcOssiProdId,
      ...dataItem,
      UcOssiUseStackVarFlg: dataItem.UcOssiUseStackVarFlg ? 1 : 0,
    };

    if (!isFromValid({ formData, requiredFields })) {
      GenerateNotification(
        buildNotification({
          title: 'Error',
          description: 'Please Fill All Required Fields',
          style: ERROR_MESSAGE?.style,
        }),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
      );
      setLoading(false);
      return;
    }

    // Check for duplicate UcOssiAfStepArgId
    const isDuplicate = argumentData.some(
      (item) =>
        item.UcOssiAfStepArgId === formData.UcOssiAfStepArgId &&
        item.row_no !== dataItem.row_no, // Ensure it's not the same row being edited
    );

    if (isDuplicate) {
      GenerateNotification(
        buildNotification({
          title: 'Error',
          description: 'Argument Id already exists. Please use a unique Id.',
          style: ERROR_MESSAGE?.style,
        }),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
      );
      setLoading(false);
      return;
    }

    try {
      const method = dataItem?.isNew
        ? createAppFlowDetailArgument
        : updateAppFlowDetailArgument;
      const notificationMetaData = dataItem?.isNew
        ? CREATE_MESSAGE
        : UPDATE_MESSAGE;

      let response = await method({
        data: formData,
        afId: flow?.UcOssiAfId,
        dtlNum: flow?.UcOssiAfDtlSeq,
        moduleName: APPFLOWDTLARG,
        isServiceWorker: false,
      });
      if (response?.IsSuccess) {
        GenerateNotification(
          buildNotification(notificationMetaData),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
        );
      } else {
        GenerateNotification(
          buildNotification(ERROR_MESSAGE),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
        );
      }
    } catch (error) {
      GenerateNotification(
        buildNotification(ERROR_MESSAGE),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
      );
    } finally {
      setEditID(null);
      fetchData();
    }
  };

  /**
   * Handles changes to grid items
   * @param {Object} e - Event data containing field and value changes
   */
  const onItemChange = (e) => {
    setArgumentData(
      argumentData.map((item) =>
        item.row_no === e.dataItem.row_no
          ? { ...item, [e.field]: e.value }
          : item,
      ),
    );
  };

  /**
   * Sets a row to edit mode
   * @param {number} row_no - The row number to be edited
   */
  const setEditRow = (row_no) => {
    setArgumentData(
      argumentData.map((item) =>
        item.row_no === row_no
          ? { ...item, inEdit: true }
          : { ...item, inEdit: false },
      ),
    );
    setEditID(row_no); // Keep track of the row being edited
  };

  /**
   * Cancels editing a row
   * @param {number} row_no - The row number to cancel editing
   */
  const cancelEdit = (row_no) => {
    const canceledRow = argumentData.find((item) => item.row_no === row_no);
    if (canceledRow?.isNew) {
      setArgumentData(argumentData.filter((item) => item.row_no !== row_no));
    } else {
      setArgumentData(
        argumentData.map((item) =>
          item.row_no === row_no ? { ...item, inEdit: false } : item,
        ),
      );
    }

    setEditID(null); // Reset the editID state when canceling
  };

  /**
   * Adds an empty row for a new argument
   */
  const addEmptyRow = () => {
    if (argumentData.some((item) => item.isNew)) {
      GenerateNotification(
        buildNotification({
          title: 'Error',
          description: 'Please save the current new row before adding another.',
          style: ERROR_MESSAGE?.style,
        }),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
      );
      return;
    }

    const newRow = {
      row_no: Math.random(),
      UcOssiAfStepArgId: '',
      UcOssiDescr: '',
      UcOssiDataVal: '',
      UcOssiUseStackVarFlg: 0,
      UcOssiAfDtlSeq: '',
      isNew: true,
    };

    setArgumentData([newRow, ...argumentData]);
    setEditID(newRow.row_no);
  };

  /**
   * Handles the deletion of an argument
   * Removes the row optimistically and triggers the API call
   */
  const onDelete = async () => {
    setVisible(false);
    // Optimistically update the UI
    setArgumentData(
      argumentData.filter(
        (item) => item.UcOssiAfStepArgId !== checkedDataItem.UcOssiAfStepArgId,
      ),
    );
    setLoading(true);
    const data = {
      afId: flow?.UcOssiAfId,
      dtlNum: flow?.UcOssiAfDtlSeq,
      prodId: flow?.UcOssiProdId,
      afStepArgId: checkedDataItem.UcOssiAfStepArgId,
      afDtlSeq: flow?.UcOssiAfDtlSeq,
    };
    let response = await deleteAppFlowDetailArgument({
      data,
      moduleName: APPFLOWDTLARG,
      isServiceWorker: false,
    });
    try {
      if (response?.IsSuccess) {
        GenerateNotification(
          buildNotification(DELETE_MESSAGE),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
        );
        fetchData();
      } else {
        GenerateNotification(
          buildNotification(ERROR_MESSAGE),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
        );
      }
    } catch (error) {
      GenerateNotification(
        buildNotification(ERROR_MESSAGE),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
      );
    } finally {
      setLoading(false);
    }
  };

  /**
   * Shows the delete confirmation dialog
   * @param {Object} dataItem - The data item to be deleted
   */
  const showDeleteDialog = (dataItem) => {
    setCheckedDataItem(dataItem); // Set the item to be deleted
    setVisible(true); // Show the dialog
  };

  return (
    <div className='container mx-auto'>
      {loading && <Loader />}
      <div className={'grid-wrapper'}>
        <div className='k-mt-5 k-mb-2'>
          <strong>Step ID:</strong> {flow?.UcOssiAfStepId}
        </div>
        <div className='k-mt-2 k-mb-2'>
          <strong>App Flow ID:</strong> {flow?.UcOssiAfId}
        </div>
        <Button
          icon={'plus'}
          className={'k-float-right k-mb-3'}
          themeColor='primary'
          onClick={addEmptyRow}
        >
          Add
        </Button>
        <div className='k-clear-both' />
        <div className='responsive-grid argument-grid'>
          <Grid
            style={{ height: '200px' }}
            size={'small'}
            data={argumentData.map((item) => ({
              ...item,
              inEdit: item.row_no === editID,
            }))}
            editField='inEdit'
            onItemChange={onItemChange}
            className={'argument-grid'}
          >
            {DataColumns(
              editID,
              argumentData,
              saveRow,
              cancelEdit,
              setEditRow,
              showDeleteDialog,
            ).map((column, index) => (
              <GridColumn key={index} {...column} />
            ))}
          </Grid>
        </div>
      </div>

      {/* Delete Confirmation Dialog */}
      {visible && (
        <Dialog title={'Please confirm'} onClose={() => setVisible(false)}>
          <p style={{ margin: '25px', textAlign: 'center' }}>
            Are you sure you want to delete this Argument?
          </p>
          <DialogActionsBar>
            <button
              className='k-button k-button-md k-rounded-md k-button-solid k-button-solid-base'
              onClick={() => setVisible(false)}
            >
              No
            </button>
            <button
              className='k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md'
              onClick={() => onDelete(checkedDataItem)}
            >
              Yes
            </button>
          </DialogActionsBar>
        </Dialog>
      )}
    </div>
  );
};

export default AppFlowDetailArgument;
